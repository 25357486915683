/**=====================
      67. Dashboard 2 CSS Start
==========================**/
.widget-decor {
   position: absolute;
   height: 60px;
   right: 0;
   top: 50%;
   transform: translateY(-50%);
}
.balance-widget {
   background-size: cover;
   background-repeat: no-repeat;
   background-position: right;
   .mobile-right-img {
      position: absolute;
      top: 10px;
      right: 15px;
      .left-mobile-img {
         margin-right: -20px;
      }
      .mobile-img {
         height: 130px;
      }
      [dir='rtl'] & {
         right: unset;
         left: 15px;
      }
      @media (max-width: 480px) {
         right: 0;
         .mobile-img {
            height: 100px;
         }
         [dir='rtl'] & {
            right: unset;
            left: 0;
         }
      }
   }
   [dir='rtl'] & {
      text-align: right;
   }
   &.card-body {
      padding: 20px 15px;
   }
   .purchase-btn {
      min-width: 170px;
   }
}
.small-widget {
   overflow: hidden;
   h4 {
      margin-bottom: -3px;
   }
   i {
      font-weight: 700;
      font-size: 11px;
   }
   .card-body {
      padding: 24px 15px;
   }
   .bg-gradient {
      width: 66px;
      height: 67px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100px;
      right: -12px;
      top: 50%;
      position: absolute;
      transform: translateY(-50%);
      [dir='rtl'] & {
         right: unset;
         left: -12px;
         transform: translateY(-50%) scaleX(-1);
      }
      @media (max-width: 1399px) {
         width: 60px;
         height: 60px;
      }
      svg {
         width: 25px;
         height: 25px;
         @media (max-width: 1399px) {
            width: 22px;
            height: 22px;
         }
      }
   }
   &:hover {
      transform: translateY(-5px);
      transition: 0.5s;
      .bg-gradient {
         svg {
            animation: tada 1.5s ease infinite;
         }
      }
   }
}
.appointment {
   .customer-table {
      @media (max-width: 1470px) {
         height: 268px;
      }
      @media (max-width: 1399px) {
         height: unset;
      }
   }
}
.order-wrapper {
   margin: 0 -24px -17px -17px;
}
.categories-list {
   display: flex;
   flex-direction: column;
   gap: 18px;
   @media (max-width: 767px) {
      flex-direction: row;
      flex-wrap: wrap;
   }
   li {
      gap: 10px;
      .bg-light {
         min-width: 40px;
         height: 40px;
         display: flex;
         align-items: center;
         justify-content: center;
         img {
            width: 25px;
            height: 25px;
            object-fit: contain;
            transition: 0.5s;
         }
      }
      h6 {
         a {
            transition: 0.5s;
            color: var(--body-font-color);
         }
      }
      &:hover {
         .bg-light {
            img {
               transition: 0.5s;
               transform: scale(1.1);
            }
         }
         h6 {
            a {
               transition: 0.5s;
               color: var(--theme-deafult);
            }
         }
      }
   }
}
.monthly-profit {
   margin-top: -10px;
   @media (max-width: 1584px) {
      margin: -10px -8px 0;
   }
   @media (max-width: 1520px) {
      margin: -10px -16px 0;
   }
   @media (max-width: 1500px) {
      margin: -10px -14px 0;
   }
   @media (max-width: 1472px) {
      margin: -10px -20px 0;
   }
   @media (max-width: 1424px) {
      margin: -10px -25px 0;
   }
   .apexcharts-canvas {
      .apexcharts-legend-marker {
         margin-right: 6px;
      }
      .apexcharts-datalabels-group {
         .apexcharts-datalabel-value {
            font-size: 14px;
            font-weight: 500;
            font-family: $font-rubik, $font-serif !important;
            fill: var(--chart-text-color);
         }
      }
   }
}
.overview-wrapper {
   position: relative;
   z-index: 1;
}
.back-bar-container {
   position: absolute;
   width: calc(100% - 64px);
   bottom: -8px;
   margin: 0 auto !important;
   left: 28px;
   @media (max-width: 1199px) {
      bottom: 22px;
   }
   @media (max-width: 480px) {
      width: calc(100% - 34px);
      left: 18px;
   }
   @media (max-width: 327px) {
      bottom: 42px;
   }
}
.overview-card {
   .balance-data {
      right: 12px;
      [dir='rtl'] & {
         right: unset;
         left: 12px;
      }
      @media (max-width: 767px) {
         right: -40%;
         [dir='rtl'] & {
            left: -40%;
         }
      }
   }
}
.order-container {
   .apexcharts-canvas {
      .apexcharts-marker {
         stroke-width: 4;
      }
   }
}
.purchase-card.discover {
   margin-top: 102px;
   img {
      width: 224px;
      margin: 0 auto;
      margin-top: -90px;
      @media (max-width: 1550px) {
         margin-top: -110px;
      }
      @media (max-width: 1399px) {
         margin-top: -90px;
      }
      @media (max-width: 991px) {
         margin-top: -110px;
      }
      @media (max-width: 618px) {
         width: 200px;
      }
   }
}
.visitor-card {
   .card-header {
      svg {
         width: 18px;
         height: 18px;
         fill: var(--theme-deafult);
      }
   }
}
.visitors-container {
   margin: 0 -12px -27px -17px;
   svg {
      .apexcharts-series {
         path {
            clip-path: inset(1% 0% 0% 0% round 3rem);
         }
      }
      .apexcharts-legend.apexcharts-align-left {
         .apexcharts-legend-series {
            display: flex;
         }
      }
   }
}
.recent-order {
   .nav {
      gap: 8px;
      flex-wrap: nowrap;
      overflow: auto;
      padding-bottom: 5px;
      display: flex;
   }
   .frame-box {
      border: 1px solid transparent;
      padding: 0;
      transition: 0.5s;
      &.active {
         border: 1px solid var(--theme-deafult);
      }
      &:hover {
         transform: translateY(-5px);
         transition: 0.5s;
      }
   }
   .tab-content {
      margin-top: 16px;
   }
}
.recent-table {
   table {
      thead {
         background: var(--light2);
         th {
            padding-top: 9px;
            padding-bottom: 9px;
            border-bottom: none;
         }
      }
      tr {
         td,
         th {
            padding: 12px 8px;
            vertical-align: middle;
            &:first-child {
               min-width: 157px;
            }
            &:nth-child(2) {
               @media (max-width: 1660px) {
                  min-width: 97px;
               }
            }
            &:last-child {
               min-width: 96px;
            }
         }
         td {
            &:first-child {
               padding-left: 0;
               [dir='rtl'] & {
                  padding-left: unset;
                  padding-right: 0;
               }
            }
            &:last-child {
               padding-right: 0;
               [dir='rtl'] & {
                  padding-left: 0;
                  padding-right: unset;
               }
            }
         }
         &:last-child {
            td {
               padding-bottom: 0;
               border-bottom: none;
            }
         }
         .product-content {
            h6 {
               a {
                  color: var(--body-font-color);
                  transition: 0.5s;
               }
            }
         }
         &:hover {
            .product-content {
               h6 {
                  a {
                     color: var(--theme-deafult);
                     transition: 0.5s;
                  }
               }
            }
         }
      }
   }
   .product-content {
      display: flex;
      align-items: center;
      gap: 8px;
      .order-image {
         background: var(--light2);
         width: 40px;
         height: 40px;
         display: flex;
         align-items: center;
         justify-content: center;
      }
   }
   svg {
      width: 20px;
      height: 20px;
   }
   .recent-status {
      display: flex;
      align-items: center;
      &.font-success {
         svg {
            fill: $success-color;
         }
      }
      &.font-danger {
         svg {
            fill: $danger-color;
         }
      }
   }
}
.recent-activity {
   h5 {
      padding: 30px 0 20px;
      margin-bottom: 0;
   }
   h6 {
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      display: -webkit-box;
   }
   ul {
      li {
         span {
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            display: -webkit-box;
         }
      }
   }
}
.notification {
   li {
      .recent-images {
         ul {
            &::before {
               display: none;
            }
         }
         li {
            padding-bottom: 3px;
         }
      }
   }
}
.recent-images {
   margin-top: 10px;
   ul {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 8px;
   }
   li {
      border: 1px dashed var(--recent-dashed-border);
      padding: 3px;
      border-radius: 2px;
      .recent-img-wrap {
         width: 40px;
         height: 40px;
         background: var(--light2);
         display: flex;
         align-items: center;
         justify-content: center;
      }
   }
}
.frame-box {
   background: var(--recent-box-bg);
   border-radius: 10px;
   min-width: 76px;
   box-shadow: 2px 2px 2px var(--recent-border);
   @media (max-width: 575px) {
      min-width: 65px;
   }
   .frame-image {
      min-width: 62px;
      height: 62px;
      border-color: var(--recent-border) var(--white) var(--white) var(--recent-border);
      border-width: 1px;
      border-style: solid;
      margin: 6px;
      display: flex;
      align-items: center;
      border-radius: 4px;
      @media (max-width: 575px) {
         min-width: 50px;
         height: 50px;
         margin: 4px;
      }
   }
   img {
      margin: 0 auto;
      @media (max-width: 575px) {
         height: 30px;
      }
   }
}

.support-ticket-font {
   ul {
      font-size: 12px;
   }
}
.new-update {
   .media {
      .media-body {
         span,
         p {
            font-weight: 500;
         }
      }
   }
}

@each $activity-dot-name, $activity-dot-color in (primary, $primary-color),
   (secondary, $secondary-color), (success, $success-color), (danger, $danger-color),
   (info, $info-color), (light, $light-color), (dark, $dark-color),
   (warning, $warning-color)
{
   .activity-dot-#{$activity-dot-name} {
      min-width: 6px;
      width: 6px;
      height: 6px;
      background-color: $activity-dot-color;
      border-radius: 100%;
      outline: 5px solid rgba($activity-dot-color, 0.25);
      position: relative;
      z-index: 2;
   }
}

// responsive
@media only screen and (max-width: 1800px) and (min-width: 1400px) {
   .grid-ed-none {
      display: none !important;
   }
   .grid-ed-12 {
      width: 100%;
   }
}
@media only screen and (max-width: 1660px) and (min-width: 1400px) {
   .col-ed-12 {
      width: 100%;
   }
   .col-ed-7 {
      width: 58%;
   }
   .col-ed-5 {
      width: 42%;
   }
   .col-ed-9 {
      width: 75%;
   }
   .col-ed-3 {
      width: 25%;
   }
   .col-ed-6 {
      width: 50%;
   }
   .col-ed-4 {
      width: 33.33333333%;
   }
   .col-ed-8 {
      width: 66.66%;
   }
   .col-ed-none {
      display: none !important;
   }
}
@media only screen and (max-width: 1660px) and (min-width: 1200px) {
   .xl-30 {
      max-width: 30%;
      flex: 0 0 30%;
   }
   .xl-70 {
      max-width: 70%;
      flex: 0 0 70%;
   }
}

@media only screen and (max-width: 420px) {
   .size-column {
      .col-6 {
         width: 100%;
      }
   }
}

/**=====================
     67. Dashboard 2  CSS End
==========================**/
