.react-kanban-board {
   max-height: calc(90vh - 300px);
   min-height: 500px;
   overflow-y: auto !important;
}

.jkanban-container
   .kanban-container
   .kanban-drag
   > div
   > div
   .react-kanban-column {
   width: 200px;
}

.jkanban-container
   .kanban-container
   .kanban-drag
   > div
   > div
   .react-kanban-column
   > div {
   width: 200px;
}

.jkanban-container .kanban-container .kanban-drag > div {
   width: auto;
}

.jkanban-container .kanban-container .kanban-drag > div > div {
   flex-wrap: nowrap;
}

.react-kanban-column {
   min-height: 100%;
   display: flex;
   flex-direction: column;
}

.react-kanban-column-scroll-container {
   flex-grow: 1;
   overflow: auto;
}

/* .jkanban-container .custom-board .kanban-drag .react-kanban-column div ~ div {
  background-color: rgba(255, 170, 5, 0.3);
}

 .jkanban-container .custom-board .kanban-drag .react-kanban-column div {
  background-color: #ffaa05;
}  */
